import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { runnerClassToGender, useResults } from "./queries";
import css from "./Leaderboard.module.css";
export default function Leaderboard(props) {
    const overallResults = useResults(runnerClassToGender(props.group), "overall");
    const sprintResults = useResults(runnerClassToGender(props.group), "sprint");
    const climbResults = useResults(runnerClassToGender(props.group), "climb");
    const sprintLatest = useResults(runnerClassToGender(props.group), "sprint-latest");
    const climbLatest = useResults(runnerClassToGender(props.group), "climb-latest");
    return (_jsxs("div", Object.assign({ className: css.leaderboard }, { children: [_jsx("h3", { children: "Overall Results" }), _jsx(Table, { results: overallResults, top: 10 }), _jsx("br", {}), _jsxs("div", { children: [_jsx("h3", { children: "Sprint Totals" }), _jsx(Table, { results: sprintResults, top: 10 })] }), _jsx("br", {}), _jsxs("div", { children: [_jsx("h3", { children: "Climb Totals" }), _jsx(Table, { results: climbResults, top: 10 })] }), _jsxs("div", { children: [_jsx("h3", { children: "Sprint Latest" }), (sprintLatest === null || sprintLatest === void 0 ? void 0 : sprintLatest.subheading) && (_jsx("h4", { children: sprintLatest === null || sprintLatest === void 0 ? void 0 : sprintLatest.subheading })), _jsx(Table, { results: sprintLatest, top: 10 })] }), _jsxs("div", { children: [_jsx("h3", { children: "Climb Latest" }), (climbLatest === null || climbLatest === void 0 ? void 0 : climbLatest.subheading) && _jsx("h4", { children: climbLatest === null || climbLatest === void 0 ? void 0 : climbLatest.subheading }), _jsx(Table, { results: climbLatest, top: 10 })] })] })));
}
function Table(props) {
    var _a;
    if (!props.results || !props.results.runners) {
        return null;
    }
    const results = props.results.runners.slice(0, (_a = props.top) !== null && _a !== void 0 ? _a : props.results.runners.length);
    return (_jsxs("table", { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Position" }), _jsx("th", { children: "Name" }), _jsx("th", { children: "Result" })] }) }), _jsx("tbody", { children: results.map((a, i) => {
                    var _a;
                    return (_jsxs("tr", { children: [_jsx("td", { children: a.position }), _jsxs("td", Object.assign({ className: css.name }, { children: [`${a.firstName} ${(_a = a.lastName) === null || _a === void 0 ? void 0 : _a.toUpperCase()}`, a.isU23 && "*", a.teamName && (_jsxs(_Fragment, { children: [_jsx("br", {}), "(", a.teamName, ")"] }))] })), _jsx("td", { children: a.result })] }, `leaderboard-row-${i}`));
                }) })] }));
}
