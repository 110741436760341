import BallaratCity from "./ballarat-city.png";
import McCafe from "./mccafe-ballarat-rect.png";
import FedUni from "../federation-uni.png";
const Logos = {
    ballarat_city: {
        src: BallaratCity,
        title: "Ballarat City",
        tickerOnly: true,
    },
    mccafe: {
        src: McCafe,
        title: "McCafe",
        tickerOnly: false,
    },
    feduni: {
        src: FedUni,
        title: "Federation Uni",
        tickerOnly: true,
    },
};
export default Logos;
