import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import cn from "classnames";
import { doTruckAction, } from "../queries";
import css from "./ComponentButton.module.css";
export function ComponentButton(props) {
    const disabled = props.disabled ||
        !props.truckId ||
        !props.config ||
        props.truckVersion == null;
    const classNames = cn(css.button, {
        [css.active]: props.active,
        [css.disabled]: disabled,
    });
    const handleClick = useCallback(() => {
        if (disabled ||
            !props.truckId ||
            !props.config ||
            props.truckVersion == null) {
            return;
        }
        if (props.active) {
            let config = null;
            if (props.customHideConfig) {
                config = props.customHideConfig(props.config);
            }
            doTruckAction(props.truckId, props.truckVersion, {
                [props.id]: config,
            });
        }
        else {
            doTruckAction(props.truckId, props.truckVersion, {
                [props.id]: props.config,
            });
        }
    }, [disabled, props.truckId, props.id, props.config, props.truckVersion]);
    return (_jsx("div", Object.assign({ className: classNames, onClick: handleClick }, { children: props.title })));
}
export function PaginatedComponentButton(props) {
    var _a;
    const disabled = props.disabled ||
        !props.truckId ||
        !props.config ||
        props.truckVersion == null;
    const classNames = cn(css.button, {
        [css.active]: props.active,
        [css.disabled]: disabled,
    });
    const handleClick = useCallback(() => {
        if (disabled ||
            !props.truckId ||
            !props.config ||
            props.truckVersion == null) {
            return;
        }
        if (props.active) {
            if (props.config.page < props.pages) {
                // Increment the page
                doTruckAction(props.truckId, props.truckVersion, {
                    [props.id]: Object.assign(Object.assign({}, props.config), { page: props.config.page + 1 }),
                });
            }
            else {
                // Hide the component
                doTruckAction(props.truckId, props.truckVersion, {
                    [props.id]: null,
                });
            }
        }
        else {
            // First page
            doTruckAction(props.truckId, props.truckVersion, {
                [props.id]: props.config,
            });
        }
    }, [disabled, props.truckId, props.id, props.config, props.truckVersion]);
    return (_jsxs("div", Object.assign({ className: classNames, onClick: handleClick }, { children: [props.title, "\u00A0", props.active && (_jsxs(_Fragment, { children: ["(", (_a = props.config) === null || _a === void 0 ? void 0 : _a.page, "/", props.pages, ")"] }))] })));
}
export function HideAllButton(props) {
    const disabled = props.disabled || !props.truckId || props.truckVersion == null;
    const classNames = cn(css.button, css.clearAll, {
        [css.disabled]: disabled,
    });
    const handleClick = useCallback(() => {
        if (disabled || !props.truckId || props.truckVersion == null) {
            return;
        }
        doTruckAction(props.truckId, props.truckVersion, "hideAll");
    }, [disabled, props.truckId, props.truckVersion]);
    return (_jsx("div", Object.assign({ className: classNames, onClick: handleClick }, { children: "CLEAR ALL" })));
}
