import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useMemo } from "react";
import { createPortal } from "react-dom";
import { AttributionControl, MapContainer, Marker, Polyline, TileLayer, useMap, } from "react-leaflet";
import leaflet from "leaflet";
import { useCourseMap } from "./queries";
import Checkbox from "./Checkbox";
import css from "./Map.module.css";
import "leaflet/dist/leaflet.css";
import markerImage from "./marker.svg";
const DEFAULT_ZOOM = 13;
const FOLLOW_LEADER_ZOOM = 17;
var MarkerName;
(function (MarkerName) {
    MarkerName[MarkerName["Leader"] = 0] = "Leader";
    MarkerName[MarkerName["Chase"] = 1] = "Chase";
})(MarkerName || (MarkerName = {}));
const MarkerNames = {
    [MarkerName.Leader]: "Leader",
    [MarkerName.Chase]: "Chase",
};
export default function Map(props) {
    const { leaderDistance, leaderPosition, chasePosition, width, height } = props;
    const [followedMarker, setFollowedMarker] = useState(MarkerName.Leader);
    const course = useCourseMap(props.gender);
    const points = useMemo(() => {
        if (course) {
            return course.route.map((p) => [p.lat, p.lng]);
        }
        else {
            return [];
        }
    }, [course]);
    const followPoint = followedMarker === MarkerName.Leader && leaderPosition
        ? leaderPosition
        : followedMarker == MarkerName.Chase && chasePosition
            ? chasePosition
            : null;
    const targetDistance = followedMarker === MarkerName.Leader ? `${leaderDistance} km` : "??";
    if (!course) {
        return null;
    }
    return (_jsxs("div", { children: [_jsxs(MapContainer, Object.assign({ className: css.map, style: {
                    width: width ? width : "800px",
                    height: height ? height : "450px",
                }, zoom: DEFAULT_ZOOM, center: course.center, attributionControl: false }, { children: [_jsx(InvalidateSize, {}), _jsx(AttributionControl, { prefix: false }), _jsx(TileLayer, { url: `https://api.mapbox.com/styles/v1/${__MAPBOX_MAP_ID__}/tiles/{z}/{x}/{y}?access_token=${__MAPBOX_ACCESS_TOKEN__}`, attribution: '\u00A9 <a href="https://www.mapbox.com/about/maps/">Mapbox</a> \u00A9 <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>', maxZoom: 21, tileSize: 512, zoomOffset: -1 }), _jsx(Polyline, { positions: points }), followPoint && _jsx(FollowPoint, { point: followPoint }), followPoint && targetDistance && followedMarker !== undefined && (_jsx(MarkerWithIcon, Object.assign({ position: followPoint }, { children: _jsx(MarkerIcon, { distance: targetDistance, name: MarkerNames[followedMarker] }) })))] })), [MarkerName.Leader, MarkerName.Chase].map((m) => (_jsx(Checkbox, { title: `Follow ${MarkerNames[m]}`, checked: followedMarker === m, onChange: (b) => {
                    if (b) {
                        setFollowedMarker(m);
                    }
                    else if (followedMarker === m) {
                        setFollowedMarker(undefined);
                    }
                } }, m)))] }));
}
function MarkerWithIcon(props) {
    const iconElement = useMemo(() => document.createElement("div"), []);
    const icon = useMemo(() => leaflet.divIcon({
        html: iconElement,
        iconAnchor: [18, 40],
        className: undefined,
        iconSize: undefined,
    }), [iconElement]);
    return (_jsx(Marker, Object.assign({ position: props.position, icon: icon }, { children: createPortal(props.children, iconElement) })));
}
function MarkerIcon(props) {
    return (_jsxs("div", Object.assign({ className: css.marker }, { children: [_jsx("img", { src: markerImage }), _jsxs("div", Object.assign({ className: css.textbox }, { children: [_jsx("div", Object.assign({ className: css.distance }, { children: props.name })), _jsx("div", Object.assign({ className: css.name }, { children: props.distance }))] }))] })));
}
function FollowPoint(props) {
    const { point } = props;
    const map = useMap();
    useEffect(() => {
        map.setView([point.lat, point.lng], FOLLOW_LEADER_ZOOM);
    }, [map, point.lat, point.lng]);
    return null;
}
// We need to call invalidateSize whenever the size of the map changes.
// In particular, this can happen when the map is first rendered in
// a hidden tab that has `display: none`. For now, we just do this on
// every render, since it shouldn't be too expensive.
function InvalidateSize() {
    const map = useMap();
    useEffect(() => {
        map.invalidateSize();
    });
    return null;
}
