// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    color-scheme: dark;
    --light-grey: #505267;
    --grey: #323342;
    --dark-grey: #141428;
    --blue: #009edd;
    --text-color-failure: red;
}

html {
    font-family: sans-serif;
    background-color: var(--grey);
    color: white;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1 {
    margin: 8px 0 6px 0;
}

h2 {
    margin: 3px 0;
}

input,
button,
textarea {
    font-family: inherit;
}

input,
textarea {
    background-color: #353535;
}

hr {
    border-top: 1px solid white;
}

button {
    cursor: pointer;
    padding: 10px;
    width: 200px;
    height: 60px;
    font-size: 14pt;
    color: white;
    border: 1px solid var(--blue);
    background-color: var(--grey);
    border-radius: 10px;
}

button:hover {
    background-color: var(--blue);
}

.app-UPalDbGJEvAnNeP8DvG9 {
    text-align: center;
    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/App.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,eAAe;IACf,oBAAoB;IACpB,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,uBAAuB;IACvB,6BAA6B;IAC7B,YAAY;AAChB;;AAEA;IACI,SAAS;IACT;;oCAEgC;IAChC,mCAAmC;IACnC,kCAAkC;AACtC;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB;;AAEA;;;IAGI,oBAAoB;AACxB;;AAEA;;IAEI,yBAAyB;AAC7B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,eAAe;IACf,aAAa;IACb,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,6BAA6B;IAC7B,6BAA6B;IAC7B,mBAAmB;AACvB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;;IAEb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB","sourcesContent":[":root {\n    color-scheme: dark;\n    --light-grey: #505267;\n    --grey: #323342;\n    --dark-grey: #141428;\n    --blue: #009edd;\n    --text-color-failure: red;\n}\n\nhtml {\n    font-family: sans-serif;\n    background-color: var(--grey);\n    color: white;\n}\n\nbody {\n    margin: 0;\n    font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\",\n        \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\",\n        \"Helvetica Neue\", sans-serif;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n}\n\nh1 {\n    margin: 8px 0 6px 0;\n}\n\nh2 {\n    margin: 3px 0;\n}\n\ninput,\nbutton,\ntextarea {\n    font-family: inherit;\n}\n\ninput,\ntextarea {\n    background-color: #353535;\n}\n\nhr {\n    border-top: 1px solid white;\n}\n\nbutton {\n    cursor: pointer;\n    padding: 10px;\n    width: 200px;\n    height: 60px;\n    font-size: 14pt;\n    color: white;\n    border: 1px solid var(--blue);\n    background-color: var(--grey);\n    border-radius: 10px;\n}\n\nbutton:hover {\n    background-color: var(--blue);\n}\n\n.app {\n    text-align: center;\n    width: 100vw;\n    height: 100vh;\n\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": `app-UPalDbGJEvAnNeP8DvG9`
};
export default ___CSS_LOADER_EXPORT___;
