import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback } from "react";
import { Route, Routes, useNavigate } from "react-router";
import Dashboard from "./Dashboard";
import Graphics from "./Graphics";
import css from "./App.module.css";
function App() {
    return (_jsx("div", Object.assign({ className: css.app }, { children: _jsxs(Routes, { children: [_jsx(Route, { path: "/dashboard/*", element: _jsx(Dashboard, {}) }), _jsxs(Route, Object.assign({ path: "/graphics" }, { children: [_jsx(Route, { path: ":gender/:truckId", element: _jsx(Graphics, {}) }), _jsx(Route, { path: ":gender", element: _jsx(Graphics, {}) }), _jsx(Route, { path: "", element: _jsx(SimpleRedirect, {}) })] })), _jsx(Route, { path: "/", element: _jsx(SimpleHomePage, {}) }), _jsx(Route, { path: "/*", element: _jsx(SimpleRedirect, {}) })] }) })));
}
function SimpleHomePage() {
    const navigate = useNavigate();
    const toDashboard = useCallback(() => navigate("/dashboard"), [navigate]);
    const toMenGraphics = useCallback(() => navigate("/graphics/men"), [navigate]);
    const toWomenGraphics = useCallback(() => navigate("/graphics/women"), [navigate]);
    const toMU19Graphics = useCallback(() => navigate("/graphics/mj"), [navigate]);
    const toWU19Graphics = useCallback(() => navigate("/graphics/wj"), [navigate]);
    const toMU23Graphics = useCallback(() => navigate("/graphics/mu"), [navigate]);
    return (_jsxs(_Fragment, { children: [_jsx("h1", { children: "Cycling Road Nationals 2024: Dashboard and Graphics" }), _jsx("h2", { children: "Presented by LightningVisuals" }), _jsx("br", {}), _jsx("button", Object.assign({ onClick: toDashboard }, { children: "Dashboard" })), _jsx("br", {}), _jsx("br", {}), _jsx("button", Object.assign({ onClick: toMenGraphics }, { children: "Men Graphics" })), _jsx("br", {}), _jsx("br", {}), _jsx("button", Object.assign({ onClick: toWomenGraphics }, { children: "Women Graphics" })), _jsx("br", {}), _jsx("br", {}), _jsx("button", Object.assign({ onClick: toMU19Graphics }, { children: "MU19 Graphics" })), _jsx("br", {}), _jsx("br", {}), _jsx("button", Object.assign({ onClick: toWU19Graphics }, { children: "WU19 Graphics" })), _jsx("br", {}), _jsx("br", {}), _jsx("button", Object.assign({ onClick: toMU23Graphics }, { children: "MU23 Graphics" }))] }));
}
function SimpleRedirect() {
    const navigate = useNavigate();
    const toHome = useCallback(() => navigate("/"), [navigate]);
    return (_jsxs(_Fragment, { children: [_jsx("h1", { children: "Page not found!" }), _jsx("br", {}), _jsx("button", Object.assign({ onClick: toHome }, { children: "Home" }))] }));
}
export default App;
