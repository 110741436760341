// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-ToDDDhZjq7GffkGMQw_O {
    cursor: pointer;
    padding: 9px;
    width: 190px;
    height: 26px;

    font-size: 13pt;
    color: white;
    text-transform: uppercase;

    border: 1px solid var(--blue);
    background-color: var(--grey);
    border-radius: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.button-ToDDDhZjq7GffkGMQw_O.active-Ks8XWroMXXqEMJcQDk5w {
    background-color: var(--blue);
}

.button-ToDDDhZjq7GffkGMQw_O.disabled-qVWi2_3Lc5XbS6bVGTQ9 {
    cursor: not-allowed;
    color: var(--light-grey);
    border: 1px solid var(--dark-grey);
}

.clear-all-alzrAXmpJiu7IXlJR8_S {
    margin-top: 5px;

    border-color: #bb0016;
    color: #ee0026;
}
`, "",{"version":3,"sources":["webpack://./src/Graphics/ComponentButton.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,YAAY;IACZ,YAAY;;IAEZ,eAAe;IACf,YAAY;IACZ,yBAAyB;;IAEzB,6BAA6B;IAC7B,6BAA6B;IAC7B,mBAAmB;;IAEnB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,mBAAmB;IACnB,wBAAwB;IACxB,kCAAkC;AACtC;;AAEA;IACI,eAAe;;IAEf,qBAAqB;IACrB,cAAc;AAClB","sourcesContent":[".button {\n    cursor: pointer;\n    padding: 9px;\n    width: 190px;\n    height: 26px;\n\n    font-size: 13pt;\n    color: white;\n    text-transform: uppercase;\n\n    border: 1px solid var(--blue);\n    background-color: var(--grey);\n    border-radius: 10px;\n\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.button.active {\n    background-color: var(--blue);\n}\n\n.button.disabled {\n    cursor: not-allowed;\n    color: var(--light-grey);\n    border: 1px solid var(--dark-grey);\n}\n\n.clear-all {\n    margin-top: 5px;\n\n    border-color: #bb0016;\n    color: #ee0026;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `button-ToDDDhZjq7GffkGMQw_O`,
	"active": `active-Ks8XWroMXXqEMJcQDk5w`,
	"disabled": `disabled-qVWi2_3Lc5XbS6bVGTQ9`,
	"clear-all": `clear-all-alzrAXmpJiu7IXlJR8_S`,
	"clearAll": `clear-all-alzrAXmpJiu7IXlJR8_S`
};
export default ___CSS_LOADER_EXPORT___;
