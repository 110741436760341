// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox-container-lScjmo0XW969CxXyDm8g {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    margin: 10px;
    cursor: pointer;

    --checkbox-size: 28px;
}

.checkbox-container-lScjmo0XW969CxXyDm8g > svg {
    border: 1px solid white;
    padding: 2px;

    margin-right: calc(var(--checkbox-size) / 2);
}

.checkbox-container-lScjmo0XW969CxXyDm8g > svg path {
    fill: white;
}

.checkbox-container-lScjmo0XW969CxXyDm8g.checked-ZKaTXpijGZnFZG8TCxzQ > svg {
    background-color: var(--blue);
}

.checkbox-container-lScjmo0XW969CxXyDm8g .checkbox-unchecked-d1Zzu_0tIQdb07r9UHm0 {
    width: var(--checkbox-size);
    height: var(--checkbox-size);
    margin-right: calc(var(--checkbox-size) / 2);
    border: 1px solid white;
    background-color: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/Checkbox.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,2BAA2B;;IAE3B,YAAY;IACZ,eAAe;;IAEf,qBAAqB;AACzB;;AAEA;IACI,uBAAuB;IACvB,YAAY;;IAEZ,4CAA4C;AAChD;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,2BAA2B;IAC3B,4BAA4B;IAC5B,4CAA4C;IAC5C,uBAAuB;IACvB,6BAA6B;AACjC","sourcesContent":[".checkbox-container {\n    display: flex;\n    align-items: center;\n    justify-content: flex-start;\n\n    margin: 10px;\n    cursor: pointer;\n\n    --checkbox-size: 28px;\n}\n\n.checkbox-container > svg {\n    border: 1px solid white;\n    padding: 2px;\n\n    margin-right: calc(var(--checkbox-size) / 2);\n}\n\n.checkbox-container > svg path {\n    fill: white;\n}\n\n.checkbox-container.checked > svg {\n    background-color: var(--blue);\n}\n\n.checkbox-container .checkbox-unchecked {\n    width: var(--checkbox-size);\n    height: var(--checkbox-size);\n    margin-right: calc(var(--checkbox-size) / 2);\n    border: 1px solid white;\n    background-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox-container": `checkbox-container-lScjmo0XW969CxXyDm8g`,
	"checkboxContainer": `checkbox-container-lScjmo0XW969CxXyDm8g`,
	"checked": `checked-ZKaTXpijGZnFZG8TCxzQ`,
	"checkbox-unchecked": `checkbox-unchecked-d1Zzu_0tIQdb07r9UHm0`,
	"checkboxUnchecked": `checkbox-unchecked-d1Zzu_0tIQdb07r9UHm0`
};
export default ___CSS_LOADER_EXPORT___;
