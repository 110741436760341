import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import cn from "classnames";
import css from "./RunnersTable.module.css";
export default function RunnersTable(props) {
    const { currentRunner, onRunnerSelected } = props;
    const runners = props.runners.sort((a, b) => a.bibNumber - b.bibNumber);
    return (_jsx("div", Object.assign({ className: css.runnersTable }, { children: runners.map((runner) => {
            const classes = cn(css.runner, {
                [css.selected]: runner.bibNumber === currentRunner,
            });
            return (_jsx("div", Object.assign({ className: classes, onClick: () => onRunnerSelected(runner.bibNumber) }, { children: _jsxs("div", { children: [runner.isElite && "[TC] ", _jsx("b", { children: runner.bibNumber }), ".\u00A0", runner.firstName, "\u00A0", runner.lastName, runner.isU23 && "*", runner.teamCode !== null && (_jsxs(_Fragment, { children: [_jsx("br", {}), "(", runner.teamName, ")"] }))] }) }), runner.bibNumber));
        }) })));
}
