// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leaderboard-oh6DinmBe9_w18BpuZck {
    height: 100%;
    overflow: auto;
}

.leaderboard-oh6DinmBe9_w18BpuZck > table {
    border-collapse: collapse;
}

.leaderboard-oh6DinmBe9_w18BpuZck > table > thead {
    border-bottom: 1px solid white;
}

.leaderboard-oh6DinmBe9_w18BpuZck > table > * > tr > th,
.leaderboard-oh6DinmBe9_w18BpuZck > table > * > tr > td {
    padding: 5px;
}

.name-Hj179C6gauhbiN9zS53C {
    text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/Leaderboard.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,8BAA8B;AAClC;;AAEA;;IAEI,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".leaderboard {\n    height: 100%;\n    overflow: auto;\n}\n\n.leaderboard > table {\n    border-collapse: collapse;\n}\n\n.leaderboard > table > thead {\n    border-bottom: 1px solid white;\n}\n\n.leaderboard > table > * > tr > th,\n.leaderboard > table > * > tr > td {\n    padding: 5px;\n}\n\n.name {\n    text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"leaderboard": `leaderboard-oh6DinmBe9_w18BpuZck`,
	"name": `name-Hj179C6gauhbiN9zS53C`
};
export default ___CSS_LOADER_EXPORT___;
