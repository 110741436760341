// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-ZkHdsamwWHNPD9UcEor9 {
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.content-WtbhaiXWXGfigpQZLIlq {
    width: 100%;
    height: calc(100% - 200px);
    display: flex;
    flex-direction: row;
}

.panel-XibDUYrDXVfk7cTGz3EE {
    margin: 10px;
}

.buttons-AB0N5zUjLbO6V53qdSAk {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.runner-details-panel-vtlVDKtD2jE72R7ieRsc {
    width: 450px;
}

.leaderboard-panel-dlZpZjjb00xv9NVm_8s8 {
    width: 500px;
}
`, "",{"version":3,"sources":["webpack://./src/Dashboard/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,2BAA2B;AAC/B;;AAEA;IACI,WAAW;IACX,0BAA0B;IAC1B,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".header {\n    height: 200px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: flex-start;\n}\n\n.content {\n    width: 100%;\n    height: calc(100% - 200px);\n    display: flex;\n    flex-direction: row;\n}\n\n.panel {\n    margin: 10px;\n}\n\n.buttons {\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: center;\n}\n\n.runner-details-panel {\n    width: 450px;\n}\n\n.leaderboard-panel {\n    width: 500px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `header-ZkHdsamwWHNPD9UcEor9`,
	"content": `content-WtbhaiXWXGfigpQZLIlq`,
	"panel": `panel-XibDUYrDXVfk7cTGz3EE`,
	"buttons": `buttons-AB0N5zUjLbO6V53qdSAk`,
	"runner-details-panel": `runner-details-panel-vtlVDKtD2jE72R7ieRsc`,
	"runnerDetailsPanel": `runner-details-panel-vtlVDKtD2jE72R7ieRsc`,
	"leaderboard-panel": `leaderboard-panel-dlZpZjjb00xv9NVm_8s8`,
	"leaderboardPanel": `leaderboard-panel-dlZpZjjb00xv9NVm_8s8`
};
export default ___CSS_LOADER_EXPORT___;
