export default function formatTime(totalMilliseconds, includeHoursIfEmpty = true) {
    const totalSeconds = totalMilliseconds / 1000;
    const totalWholeSeconds = Math.floor(totalSeconds);
    const seconds = totalWholeSeconds % 60;
    const minutes = ((totalWholeSeconds - seconds) / 60) % 60;
    const hours = (totalWholeSeconds - seconds - minutes * 60) / 3600;
    const secondsDisplay = seconds.toString().padStart(2, "0");
    let minutesDisplay;
    if (minutes > 0) {
        minutesDisplay = `${minutes.toString().padStart(2, "0")}:`;
    }
    else {
        minutesDisplay = "00:";
    }
    let hoursDisplay = "";
    if (hours > 0) {
        hoursDisplay = `${hours}:`;
    }
    else if (includeHoursIfEmpty) {
        hoursDisplay = "0:";
    }
    return `${hoursDisplay}${minutesDisplay}${secondsDisplay}`;
}
