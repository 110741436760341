import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import Flag from "react-world-flags";
import formatTime from "../formatTime";
import css from "./RunnerDetails.module.css";
function getAge(dateStr) {
    const today = new Date();
    const dob = new Date(dateStr);
    let age = today.getFullYear() - dob.getFullYear();
    dob.setFullYear(today.getFullYear());
    if (today.valueOf() < dob.valueOf()) {
        age--;
    }
    return age;
}
export default function RunnerDetails(props) {
    const { runner } = props;
    return (_jsxs("div", Object.assign({ className: css.runnerDetails }, { children: [_jsxs("h1", { children: [runner.firstName, " ", runner.lastName] }), _jsxs("h2", Object.assign({ className: css.flagContainer }, { children: [_jsx("div", { children: runner.countryCode }), _jsx("div", Object.assign({ className: css.flag }, { children: _jsx(Flag, { code: runner.countryCode, height: "30px" }) }))] })), _jsxs("h2", { children: ["DOB:\u00A0", runner.dateOfBirth ? (_jsxs(_Fragment, { children: [runner.dateOfBirth, " (", getAge(runner.dateOfBirth), ")"] })) : (_jsx(_Fragment, { children: "\u2014" }))] }), _jsxs("h2", { children: ["Personal Best: ", formatTime(runner.personalBestTime)] }), _jsx("hr", {}), _jsx("h2", { children: "Splits" }), _jsxs("table", Object.assign({ className: css.splits }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", { children: "Name" }), _jsx("th", { children: "Overall" }), _jsx("th", { children: "Split" }), _jsx("th", { children: "Estimated Time" })] }) }), _jsx("tbody", { children: runner.splits.map((split, i) => (_jsxs("tr", { children: [_jsx("td", { children: split.name }), _jsx("td", { children: formatTime(split.raceTime) }), _jsx("td", { children: formatTime(split.splitTime) }), _jsx("td", { children: split.estimatedFinishTime ? (formatTime(split.estimatedFinishTime)) : (_jsx(_Fragment, { children: "\u2014" })) })] }, `split-${i}`))) })] }))] })));
}
