// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container-iJFKdokZFBZk6y7Dv7VJ {
    margin-top: 20px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.table-jdtyciLZc6_TIkbAu30r {
    table-layout: fixed;
    max-width: 900px;

    border-collapse: collapse;
}

.table-jdtyciLZc6_TIkbAu30r > tbody > tr {
    border-bottom: 1px solid white;
}

.table-headers-K_lwsDKkmULApWJiAttC {
    font-weight: bold;
    border-bottom: 2px solid white;
}

.table-row-Q0iitm83EHoUnEDQFD20 > td {
    text-align: center;
}

.table-cell-Ar3zFdd9cyHx_HSCLeMH > input {
    word-break: break-all;
}

.long-cell-wxnpGC5xvbjJfuycd4Ci {
    width: 200px !important;
}

.short-cell-q_pmJV8TngW7Fznl1MEo {
    width: 50px !important;
}

.selected-cell-ebwMFFnfviJFT3OxqUCC {
    padding: 5px;

    width: 50px !important;
}

.delete-A_lXHopzXXjwymNjpvXV {
    cursor: pointer;
    font-weight: bold;
}

.table-cell-Ar3zFdd9cyHx_HSCLeMH {
    padding: 12px 10px;
    width: 120px;
}

.table-cell-long-BGkaQ4FqIScLUufUHyFW {
    padding: 5px 10px;
    width: 250px;
}

.table-cell-Ar3zFdd9cyHx_HSCLeMH :is(textarea, input) {
    width: 90%;
    padding: 6px 8px;
    box-sizing: border-box;
    overflow: hidden;
}

.logo-preview-zO4EjdNxwplSfFNt_BuZ {
    height: 30px;
    max-width: 100px;
}

.add-new-row-QW6MFJbyR1P20cYp1X2v {
    font-size: 30px;
    cursor: pointer;
}
.add-new-row-QW6MFJbyR1P20cYp1X2v:hover {
    color: var(--dark-grey);
}
`, "",{"version":3,"sources":["webpack://./src/Graphics/EditableTable.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;;IAEhB,yBAAyB;AAC7B;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,iBAAiB;IACjB,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,YAAY;;IAEZ,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,gBAAgB;IAChB,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,eAAe;AACnB;AACA;IACI,uBAAuB;AAC3B","sourcesContent":[".table-container {\n    margin-top: 20px;\n    height: 100%;\n    overflow-y: auto;\n    overflow-x: hidden;\n}\n\n.table {\n    table-layout: fixed;\n    max-width: 900px;\n\n    border-collapse: collapse;\n}\n\n.table > tbody > tr {\n    border-bottom: 1px solid white;\n}\n\n.table-headers {\n    font-weight: bold;\n    border-bottom: 2px solid white;\n}\n\n.table-row > td {\n    text-align: center;\n}\n\n.table-cell > input {\n    word-break: break-all;\n}\n\n.long-cell {\n    width: 200px !important;\n}\n\n.short-cell {\n    width: 50px !important;\n}\n\n.selected-cell {\n    padding: 5px;\n\n    width: 50px !important;\n}\n\n.delete {\n    cursor: pointer;\n    font-weight: bold;\n}\n\n.table-cell {\n    padding: 12px 10px;\n    width: 120px;\n}\n\n.table-cell-long {\n    padding: 5px 10px;\n    width: 250px;\n}\n\n.table-cell :is(textarea, input) {\n    width: 90%;\n    padding: 6px 8px;\n    box-sizing: border-box;\n    overflow: hidden;\n}\n\n.logo-preview {\n    height: 30px;\n    max-width: 100px;\n}\n\n.add-new-row {\n    font-size: 30px;\n    cursor: pointer;\n}\n.add-new-row:hover {\n    color: var(--dark-grey);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table-container": `table-container-iJFKdokZFBZk6y7Dv7VJ`,
	"tableContainer": `table-container-iJFKdokZFBZk6y7Dv7VJ`,
	"table": `table-jdtyciLZc6_TIkbAu30r`,
	"table-headers": `table-headers-K_lwsDKkmULApWJiAttC`,
	"tableHeaders": `table-headers-K_lwsDKkmULApWJiAttC`,
	"table-row": `table-row-Q0iitm83EHoUnEDQFD20`,
	"tableRow": `table-row-Q0iitm83EHoUnEDQFD20`,
	"table-cell": `table-cell-Ar3zFdd9cyHx_HSCLeMH`,
	"tableCell": `table-cell-Ar3zFdd9cyHx_HSCLeMH`,
	"long-cell": `long-cell-wxnpGC5xvbjJfuycd4Ci`,
	"longCell": `long-cell-wxnpGC5xvbjJfuycd4Ci`,
	"short-cell": `short-cell-q_pmJV8TngW7Fznl1MEo`,
	"shortCell": `short-cell-q_pmJV8TngW7Fznl1MEo`,
	"selected-cell": `selected-cell-ebwMFFnfviJFT3OxqUCC`,
	"selectedCell": `selected-cell-ebwMFFnfviJFT3OxqUCC`,
	"delete": `delete-A_lXHopzXXjwymNjpvXV`,
	"table-cell-long": `table-cell-long-BGkaQ4FqIScLUufUHyFW`,
	"tableCellLong": `table-cell-long-BGkaQ4FqIScLUufUHyFW`,
	"logo-preview": `logo-preview-zO4EjdNxwplSfFNt_BuZ`,
	"logoPreview": `logo-preview-zO4EjdNxwplSfFNt_BuZ`,
	"add-new-row": `add-new-row-QW6MFJbyR1P20cYp1X2v`,
	"addNewRow": `add-new-row-QW6MFJbyR1P20cYp1X2v`
};
export default ___CSS_LOADER_EXPORT___;
