// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.runner-details-kwRxQ1NeygnSAeOU0fyt {
    width: 450px;
    height: 670px;
    border: 1px solid white;
    border-radius: 5px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.flag-container-EUdIwmoVv2gMKtUWqNUd {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flag-hKUS5iJbfDqPa2hFCk3O {
    display: flex;
    align-items: center;

    margin-left: 10px;
}

.splits-uMJaVEvj6JrrvhM18L1a {
    border-collapse: collapse;
}

.splits-uMJaVEvj6JrrvhM18L1a > thead {
    border-bottom: 1px solid white;
}

.splits-uMJaVEvj6JrrvhM18L1a > * > tr > th,
.splits-uMJaVEvj6JrrvhM18L1a > * > tr > td {
    padding: 5px 10px;
}
`, "",{"version":3,"sources":["webpack://./src/Dashboard/RunnerDetails.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,kBAAkB;;IAElB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;;IAEnB,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,8BAA8B;AAClC;;AAEA;;IAEI,iBAAiB;AACrB","sourcesContent":[".runner-details {\n    width: 450px;\n    height: 670px;\n    border: 1px solid white;\n    border-radius: 5px;\n\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.flag-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.flag {\n    display: flex;\n    align-items: center;\n\n    margin-left: 10px;\n}\n\n.splits {\n    border-collapse: collapse;\n}\n\n.splits > thead {\n    border-bottom: 1px solid white;\n}\n\n.splits > * > tr > th,\n.splits > * > tr > td {\n    padding: 5px 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"runner-details": `runner-details-kwRxQ1NeygnSAeOU0fyt`,
	"runnerDetails": `runner-details-kwRxQ1NeygnSAeOU0fyt`,
	"flag-container": `flag-container-EUdIwmoVv2gMKtUWqNUd`,
	"flagContainer": `flag-container-EUdIwmoVv2gMKtUWqNUd`,
	"flag": `flag-hKUS5iJbfDqPa2hFCk3O`,
	"splits": `splits-uMJaVEvj6JrrvhM18L1a`
};
export default ___CSS_LOADER_EXPORT___;
