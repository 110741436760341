import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import formatTime from "../formatTime";
import RunnersTable from "../RunnersTable";
import Map from "../Map";
import { RunnerClass } from "../types";
import RunnerDetails from "./RunnerDetails";
import cn from "classnames";
import { useNavigate } from "react-router";
import { useCallback, useState } from "react";
import { runnerClassToGender, useLiveTiming, useRunnerDetails, useRunnersList, } from "../queries";
import Leaderboard from "../Leaderboard";
import headerCss from "../header.module.css";
import css from "./index.module.css";
export default function Dashboard() {
    var _a, _b, _c, _d;
    const navigate = useNavigate();
    const toHome = useCallback(() => navigate("/"), [navigate]);
    const [runnerClass, setRunnerClass] = useState(RunnerClass.Men);
    const [selectedRunner, setSelectedRunner] = useState();
    const genderLiveTiming = useLiveTiming(runnerClassToGender(runnerClass));
    const runners = useRunnersList(runnerClassToGender(runnerClass));
    const runner = useRunnerDetails(selectedRunner);
    const leaderDistance = (_a = genderLiveTiming === null || genderLiveTiming === void 0 ? void 0 : genderLiveTiming.leaderDistanceRemaining) !== null && _a !== void 0 ? _a : 0;
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: css.header }, { children: [_jsx("h1", { children: _jsx("div", Object.assign({ className: headerCss.title, onClick: toHome }, { children: "Cycling Dashboard" })) }), _jsxs("h2", { children: ["Elapsed Time: ", formatTime((_b = genderLiveTiming === null || genderLiveTiming === void 0 ? void 0 : genderLiveTiming.raceTime) !== null && _b !== void 0 ? _b : 0)] }), _jsxs("h2", { children: ["Elapsed Distance: ", leaderDistance, "km"] }), _jsxs("div", Object.assign({ className: headerCss.menWomenSelector }, { children: [_jsx("div", { children: "Men" }), _jsxs("label", Object.assign({ className: headerCss.switch }, { children: [_jsx("input", { onChange: (ev) => setRunnerClass(ev.target.checked
                                            ? RunnerClass.Women
                                            : RunnerClass.Men), checked: runnerClass === RunnerClass.Women, type: "checkbox" }), _jsx("span", { className: cn(headerCss.slider, headerCss.round) })] })), _jsx("div", { children: "Women" })] }))] })), _jsxs("div", Object.assign({ className: css.content }, { children: [_jsx("div", Object.assign({ className: css.panel }, { children: _jsx(RunnersTable, { runners: runners, onRunnerSelected: (id) => setSelectedRunner(id), currentRunner: selectedRunner }) })), _jsx("div", Object.assign({ className: cn(css.panel, css.runnerDetailsPanel) }, { children: runner && _jsx(RunnerDetails, { runner: runner }) })), _jsx("div", Object.assign({ className: css.panel }, { children: _jsx(Map, { gender: runnerClassToGender(runnerClass), leaderDistance: leaderDistance, leaderPosition: (_c = genderLiveTiming === null || genderLiveTiming === void 0 ? void 0 : genderLiveTiming.leaderPosition) !== null && _c !== void 0 ? _c : null, chasePosition: (_d = genderLiveTiming === null || genderLiveTiming === void 0 ? void 0 : genderLiveTiming.chasePosition) !== null && _d !== void 0 ? _d : null, width: "500px" }) })), _jsxs("div", Object.assign({ className: cn(css.panel, css.leaderboardPanel) }, { children: [_jsx("h2", { children: "Leaderboard" }), _jsx(Leaderboard, { group: runnerClass, showSplitSelector: true, numResults: 99 })] }))] }))] }));
}
