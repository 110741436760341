import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useCallback, useRef } from "react";
import Checkbox from "../Checkbox";
import { SuperType } from "../types";
import { isEqual } from "lodash";
import cn from "classnames";
import css from "./EditableTable.module.css";
import Logos from "../assets/logos";
export default function EditableTable(props) {
    const { superType, selection, manualSuper, addOrUpdateRow, deleteRow } = props;
    const [newRowValues, setNewRowValues] = useState({});
    const emptyNewRow = () => {
        if (manualSuper === undefined)
            return {};
        const newRow = {};
        manualSuper.headers.forEach((h) => {
            newRow[h.key] = "";
        });
        return newRow;
    };
    useEffect(() => {
        if (manualSuper === undefined)
            return;
        setNewRowValues(emptyNewRow());
    }, [manualSuper === null || manualSuper === void 0 ? void 0 : manualSuper.headers]);
    const updateRowWithKey = useCallback((id, key) => (value) => {
        if (manualSuper === undefined)
            return;
        let newRow = emptyNewRow();
        Object.entries(manualSuper.values[id]).forEach(([k, v]) => {
            if (k in newRow) {
                newRow[k] = v;
            }
        });
        if (key in newRow) {
            newRow[key] = value;
        }
        else {
            return;
        }
        console.log(`UPDATE EXISTING: Row ${id} =>`, newRow);
        addOrUpdateRow(id, newRow);
    }, [manualSuper]);
    const addNewRow = () => {
        console.log(`NEW ROW: Row -1 =>`, newRowValues);
        addOrUpdateRow(-1, newRowValues);
        setNewRowValues(emptyNewRow());
    };
    if (!manualSuper) {
        return null;
    }
    return (_jsx("div", Object.assign({ className: css.tableContainer }, { children: _jsxs("table", Object.assign({ className: css.table }, { children: [_jsx("thead", { children: _jsxs("tr", Object.assign({ className: css.tableHeaders }, { children: [_jsx("th", Object.assign({ className: css.selectedCell }, { children: selection && "Selected" })), manualSuper.headers.map((h, i) => {
                                var _a, _b;
                                return (_jsx("th", Object.assign({ className: cn(css.tableCell, {
                                        [css.longCell]: ((_a = props.longKeys) !== null && _a !== void 0 ? _a : []).find((k) => k === h.key),
                                        [css.shortCell]: ((_b = props.shortKeys) !== null && _b !== void 0 ? _b : []).find((k) => k === h.key),
                                    }) }, { children: h.name }), `super-header${i}`));
                            }), _jsx("th", { children: "Delete" })] })) }), _jsxs("tbody", { children: [manualSuper.values.map((r, i) => (_jsxs("tr", Object.assign({ className: css.tableRow }, { children: [_jsx("td", Object.assign({ className: css.selectedCell }, { children: selection && (_jsx(Checkbox, { title: "", checked: isEqual(r, selection.superKeys), onChange: (b) => {
                                            if (b) {
                                                selection.setSuperKeys(r);
                                            }
                                            else {
                                                selection.setSuperKeys(undefined);
                                            }
                                        } })) })), manualSuper.headers.map((h, j) => {
                                    var _a, _b, _c, _d, _e;
                                    return h.key !== "logoId" ? (_jsx(EditableCell, { currentValue: (_a = r[h.key]) !== null && _a !== void 0 ? _a : "", placeholder: h.name, setRow: updateRowWithKey(i, h.key), isLong: !!((_b = props.longKeys) !== null && _b !== void 0 ? _b : []).find((k) => k === h.key), isShort: !!((_c = props.shortKeys) !== null && _c !== void 0 ? _c : []).find((k) => k === h.key) }, `super-row${i}-col${j}-${(_d = r[h.key]) !== null && _d !== void 0 ? _d : ""}`)) : (_jsx(LogoDropDownCell, { currentValue: (_e = r[h.key]) !== null && _e !== void 0 ? _e : "", setRow: updateRowWithKey(i, h.key), superType: superType }, `super-row${i}-col${j}`));
                                }), _jsx("td", { children: _jsx("div", Object.assign({ className: css.delete, onClick: () => deleteRow(i) }, { children: "X" })) })] }), `super-row${i}`))), _jsxs("tr", Object.assign({ className: css.tableRow }, { children: [_jsx("td", Object.assign({ className: css.selectedCell }, { children: _jsx("div", Object.assign({ className: css.addNewRow, onClick: addNewRow }, { children: "+" })) })), manualSuper.headers.map((h, i) => {
                                    var _a, _b, _c, _d;
                                    return h.key !== "logoId" ? (_jsx(EditableCell, { placeholder: h.name, setRow: (val) => {
                                            setNewRowValues((newRow) => (Object.assign(Object.assign({}, newRow), { [h.key]: val })));
                                        }, isLong: !!((_a = props.longKeys) !== null && _a !== void 0 ? _a : []).find((k) => k === h.key), isShort: !!((_b = props.shortKeys) !== null && _b !== void 0 ? _b : []).find((k) => k === h.key), currentValue: ((_c = newRowValues[h.key]) !== null && _c !== void 0 ? _c : "") }, `newrow-${manualSuper.values.length + 1}-${i}`)) : (_jsx(LogoDropDownCell, { currentValue: ((_d = newRowValues[h.key]) !== null && _d !== void 0 ? _d : ""), setRow: (val) => setNewRowValues((newRow) => (Object.assign(Object.assign({}, newRow), { [h.key]: val }))), superType: superType, showNoneOption: true }, `newrow-${manualSuper.values.length + 1}-${i}`));
                                })] }))] })] })) })));
}
function EditableCell(props) {
    const { currentValue, placeholder, setRow, isLong, isShort } = props;
    const [wip, setWip] = useState();
    const inputProps = {
        placeholder: placeholder,
        value: wip !== undefined ? wip : currentValue,
        onBlur: () => {
            if (wip !== undefined) {
                setRow(wip);
            }
        },
    };
    return (_jsx("td", Object.assign({ className: cn(css.tableCell, {
            [css.longCell]: isLong,
            [css.shortCell]: isShort,
        }), onSubmit: (ev) => {
            ev.preventDefault();
            if (wip !== undefined) {
                setRow(wip);
            }
        } }, { children: _jsx("form", { children: props.isLong ? (_jsx(AutosizedTextArea, Object.assign({ onChange: (ev) => {
                    setWip(ev.currentTarget.value);
                } }, inputProps))) : (_jsx("input", Object.assign({ type: "text", onChange: (ev) => {
                    setWip(ev.currentTarget.value);
                } }, inputProps))) }) })));
}
function AutosizedTextArea(props) {
    const ref = useRef(null);
    useEffect(() => {
        if (!ref.current) {
            return;
        }
        ref.current.style.height = ref.current.scrollHeight + "px";
    }, [props.value]);
    return _jsx("textarea", Object.assign({ ref: ref }, props));
}
function LogoDropDownCell(props) {
    var _a;
    const { currentValue, setRow } = props;
    const [value, setValue] = useState(currentValue);
    const [isEditing, setIsEditing] = useState(false);
    useEffect(() => {
        if (!isEditing) {
            // Don't replace our value if we're currently editing the textbox
            setValue(currentValue);
        }
    }, [currentValue]);
    return (_jsxs("td", Object.assign({ className: css.tableCell }, { children: [_jsxs("select", Object.assign({ value: value, onFocus: () => setIsEditing(true), onChange: (ev) => {
                    var _a;
                    if (currentValue === ev.currentTarget.value) {
                        return;
                    }
                    const v = (_a = ev.currentTarget.value) !== null && _a !== void 0 ? _a : "";
                    setRow(v);
                    setValue(v);
                    setIsEditing(false);
                } }, { children: [props.showNoneOption && _jsx("option", Object.assign({ value: "" }, { children: "None" })), Object.entries(Logos).map(([key, value]) => {
                        const hide = props.superType !== SuperType.Ticker &&
                            value.tickerOnly;
                        return (!hide && (_jsx("option", Object.assign({ value: key }, { children: value.title }), key)));
                    })] })), _jsx("br", {}), _jsx("img", { src: (_a = Logos[value]) === null || _a === void 0 ? void 0 : _a.src, className: css.logoPreview })] })));
}
