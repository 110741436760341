// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-VlljkMiudBDe6LvjCSD4 {
    z-index: 0;
}

.marker-pziXXsdBxnheepxcIzVZ {
    font-family: Roboto, sans-serif;
    display: flex;
    font-size: 20px;
}

.marker-pziXXsdBxnheepxcIzVZ .textbox-OI88_YFBY402x5JbNZYf {
    display: flex;
    font-size: 20px;
    font-style: italic;
    color: white;
    white-space: nowrap;
    position: relative;
    top: -20px;
}

.marker-pziXXsdBxnheepxcIzVZ .textbox-OI88_YFBY402x5JbNZYf > * {
    padding: 5px;
}

.marker-pziXXsdBxnheepxcIzVZ .distance-ZXjSbTttMjFQVe2YlOrL {
    background-color: rgb(0, 145, 164);
    width: 70px;
    text-align: right;
    padding-right: 10px;
}

.marker-pziXXsdBxnheepxcIzVZ .name-ljrcPPoD_l0q_yWElLUk {
    background-color: rgb(28, 25, 26);
    padding-left: 10px;
    padding-right: 10px;
    text-transform: uppercase;
}
`, "",{"version":3,"sources":["webpack://./src/Map.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,+BAA+B;IAC/B,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,kBAAkB;IAClB,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kCAAkC;IAClC,WAAW;IACX,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,iCAAiC;IACjC,kBAAkB;IAClB,mBAAmB;IACnB,yBAAyB;AAC7B","sourcesContent":[".map {\n    z-index: 0;\n}\n\n.marker {\n    font-family: Roboto, sans-serif;\n    display: flex;\n    font-size: 20px;\n}\n\n.marker .textbox {\n    display: flex;\n    font-size: 20px;\n    font-style: italic;\n    color: white;\n    white-space: nowrap;\n    position: relative;\n    top: -20px;\n}\n\n.marker .textbox > * {\n    padding: 5px;\n}\n\n.marker .distance {\n    background-color: rgb(0, 145, 164);\n    width: 70px;\n    text-align: right;\n    padding-right: 10px;\n}\n\n.marker .name {\n    background-color: rgb(28, 25, 26);\n    padding-left: 10px;\n    padding-right: 10px;\n    text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"map": `map-VlljkMiudBDe6LvjCSD4`,
	"marker": `marker-pziXXsdBxnheepxcIzVZ`,
	"textbox": `textbox-OI88_YFBY402x5JbNZYf`,
	"distance": `distance-ZXjSbTttMjFQVe2YlOrL`,
	"name": `name-ljrcPPoD_l0q_yWElLUk`
};
export default ___CSS_LOADER_EXPORT___;
