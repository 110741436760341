import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback } from "react";
import cn from "classnames";
import css from "./Checkbox.module.css";
export default function Checkbox(props) {
    const { title, checked, onChange } = props;
    const handleCheckboxClick = useCallback(() => {
        onChange(!checked);
    }, [checked, onChange]);
    const containerClassNames = cn(css.checkboxContainer, {
        [css.checked]: checked,
    });
    return (_jsxs("div", Object.assign({ className: containerClassNames, onClick: handleCheckboxClick }, { children: [checked && _jsx(TickIcon, {}), !checked && _jsx("div", { className: css.checkboxUnchecked }), title && _jsx("label", { children: title })] })));
}
function TickIcon() {
    return (_jsx("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24", version: "1.1", width: "24px", height: "24px" }, { children: _jsx("g", Object.assign({ id: "surface1" }, { children: _jsx("path", { d: "M 22.59375 3.5 L 8.0625 18.1875 L 1.40625 11.5625 L 0 13 L 8.0625 21 L 24 4.9375 Z " }) })) })));
}
