// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.runners-table-I9pxr03J7FJrEoDKFr1X {
    width: 375px;
    height: 570px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    border: 1px solid white;
    border-radius: 5px;
}

.runner-iLqB0q2fQ447Zf7wcgKD {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    padding: 15px 10px;
    margin: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
}

.flag-PK8k5NyVBa35n_5YdG34 {
    display: flex;
    align-items: center;

    margin-left: 10px;
}

.runner-iLqB0q2fQ447Zf7wcgKD:hover {
    background-color: var(--light-grey);
}

.runner-iLqB0q2fQ447Zf7wcgKD.selected-pFdheDcBHxiovgRWeEnu {
    background-color: var(--blue);
}
`, "",{"version":3,"sources":["webpack://./src/RunnersTable.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,uBAAuB;;IAEvB,kBAAkB;IAClB,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;;IAEnB,iBAAiB;AACrB;;AAEA;IACI,mCAAmC;AACvC;;AAEA;IACI,6BAA6B;AACjC","sourcesContent":[".runners-table {\n    width: 375px;\n    height: 570px;\n    overflow-y: auto;\n    display: flex;\n    flex-direction: column;\n    border: 1px solid white;\n    border-radius: 5px;\n}\n\n.runner {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: center;\n\n    padding: 15px 10px;\n    margin: 10px;\n    border: 1px solid #ddd;\n    border-radius: 5px;\n    cursor: pointer;\n}\n\n.flag {\n    display: flex;\n    align-items: center;\n\n    margin-left: 10px;\n}\n\n.runner:hover {\n    background-color: var(--light-grey);\n}\n\n.runner.selected {\n    background-color: var(--blue);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"runners-table": `runners-table-I9pxr03J7FJrEoDKFr1X`,
	"runnersTable": `runners-table-I9pxr03J7FJrEoDKFr1X`,
	"runner": `runner-iLqB0q2fQ447Zf7wcgKD`,
	"flag": `flag-PK8k5NyVBa35n_5YdG34`,
	"selected": `selected-pFdheDcBHxiovgRWeEnu`
};
export default ___CSS_LOADER_EXPORT___;
